import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

const VideoPlayIcon = ({
  ...props
}: ComponentPropsWithoutRef<'svg'>): JSX.Element => {
  const styles = {
    icon: css`
      width: min(10rem, 20%);
      height: auto;
      path {
        fill: #fff;
      }
      circle {
        fill: none;
        stroke: #fff;
      }
      transition: transform 400ms cubic-bezier(0.33, 3, 0.25, 0.5);
      @media (hover: hover) {
        div:hover > & {
          transform: scale3d(1.1, 1.1, 1);
        }
      }
    `,
  }
  return (
    <svg
      css={styles.icon}
      viewBox="0 0 120 120"
      {...props}
    >
      <path d="M47 34L87 60L47 86V34Z" />
      <circle
        cx="60"
        cy="60"
        r="58.5"
        strokeWidth="3"
      />
    </svg>
  )
}

export default VideoPlayIcon
