import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { mq } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data: Queries.IconFragment | null
  variant?: 'DEFAULT' | 'HOME_PAGE'
}

const Icon = ({
  data,
  // variant = 'DEFAULT',
  ...props
}: Props): JSX.Element => {
  const styles = {
    figure: css`
      margin: 0;
      padding: 0;
      display: grid;
      gap: 1em;
    `,
    img: css`
      width: 100%;
      height: auto;
    `,
    figcaption: css`
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.125;
      text-overflow: ellipsis;
      overflow: hidden;
      ${mq().ms} {
        font-size: var(--fs-15);
      }
      ${mq().s} {
        font-size: 1rem;
      }
    `,
  }

  return (
    <figure
      css={styles.figure}
      {...props}
    >
      <img
        css={styles.img}
        src={data?.icon?.url || undefined}
        alt={data?.title || undefined}
      />
      <figcaption css={styles.figcaption}>{data?.title}</figcaption>
    </figure>
  )
}

export const IconFragment = graphql`
  fragment Icon on DatoCmsIcon {
    id: originalId
    __typename
    icon {
      url
    }
    title
  }
`

export default Icon
