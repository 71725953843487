import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, useEffect } from 'react'

import { ScrollToggle } from '@/features/common-blocks'
import { useNavContext } from '@/features/layout'
import { useEscKeyFunction } from '@/hooks/useEscKeyFunction'
import { useWindowWidth } from '@/hooks/useWindowDimensions'

interface Props extends ComponentPropsWithoutRef<'button'> {
  breakpoint:
    | NonNullable<Queries.NavQuery['datoCmsNav']>['breakpoint']
    | null
    | undefined
}

const MobileNavButton = ({
  breakpoint,
  ...props
}: Props): JSX.Element => {
  const { mobileNavIsOpen, setMobileNavIsOpen } = useNavContext()
  const windowWidth = useWindowWidth()
  useEffect(() => {
    if ((windowWidth || 0) > (breakpoint || 0)) {
      setMobileNavIsOpen(false)
    }
  }, [windowWidth, breakpoint, setMobileNavIsOpen])
  const handleButtonClick = () => {
    setMobileNavIsOpen(prev => !prev)
  }
  useEscKeyFunction(() => {
    setMobileNavIsOpen(false)
  })
  const styles = {
    button: css`
      display: none;
      @media (max-width: ${breakpoint}px) {
        display: block;
      }
      color: #fff;
      position: relative;
      align-self: center;
      box-sizing: content-box;
      padding: 1rem;
      width: 1.5em;
      height: 1.5em;
      margin-right: 1em;
      border: none;
      appearance: none;
      background-color: transparent;
      cursor: pointer;
      pointer-events: all;
      z-index: 10;
      transition:
        color 300ms ease,
        opacity 300ms ease;

      span {
        position: absolute;
        height: 2px;
        width: 2rem;
        left: 50%;
        top: calc(50% - 1px);
        background-color: currentColor;
        ${!mobileNavIsOpen &&
        css`
          &:nth-of-type(1) {
            transition:
              transform 150ms ease 150ms,
              opacity 0ms ease 150ms;
            transform: translate(-50%, calc(-0.75em + 1px));
          }
          &:nth-of-type(2) {
            transition: transform 150ms ease;
            transform: translate(-50%, 0);
          }
          &:nth-of-type(3) {
            transition: transform 150ms ease;
            transform: translate(-50%, 0);
          }
          &:nth-of-type(4) {
            transition:
              transform 150ms ease 150ms,
              opacity 0ms ease 150ms;
            transform: translate(-50%, calc(0.75em - 1px));
          }
        `}

        ${mobileNavIsOpen &&
        css`
          &:nth-of-type(1) {
            transition:
              transform 150ms ease,
              opacity 0ms ease 150ms;
            transform: translate(-50%, 0);
            opacity: 0;
          }
          &:nth-of-type(2) {
            transition: transform 150ms ease 150ms;
            transform: translate(-50%, 0) rotate(45deg);
            transition-delay: 150ms;
          }
          &:nth-of-type(3) {
            transition: transform 150ms ease 150ms;
            transform: translate(-50%, 0) rotate(-45deg);
            transition-delay: 150ms;
          }
          &:nth-of-type(4) {
            transition:
              transform 150ms ease 150ms,
              opacity 0ms ease 150ms;
            transform: translate(-50%, 0);
            opacity: 0;
          }
        `}
      }
    `,
  }
  return (
    <button
      css={styles.button}
      onClick={handleButtonClick}
      {...props}
    >
      <span />
      <span />
      <span />
      <span />
      {mobileNavIsOpen && <ScrollToggle />}
    </button>
  )
}

export default MobileNavButton
