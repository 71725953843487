import { css } from '@emotion/react'
import { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  heading: string | ReactNode | null
  eyebrow?: string | ReactNode | null
  subheading?: string | ReactNode | null
  headingLevel?: 1 | 2
}

const ArticleHeading = ({
  heading,
  eyebrow,
  subheading,
  headingLevel = 1,
  ...props
}: Props): JSX.Element => {
  const Heading = `h${headingLevel}` as ElementType
  const Subheading = `h${headingLevel + 1}` as ElementType
  const styles = {
    container: css`
      margin-left: var(--margin);
      margin-right: var(--margin);
      padding-bottom: 2em;
      margin-bottom: 3em;
      border-bottom: 1px solid ${colors.gray85};
    `,
    eyebrow: css`
      font-family: var(--body);
      font-size: var(--fs-16);
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: ${colors.teal};
      margin: 2em 0 2em;
      line-height: 1;
    `,
    heading: css`
      font-size: var(--fs-60);
      color: ${colors.tealDark};
      margin: 0;
      line-height: 1;
      ${mq().s} {
        font-size: var(--fs-60);
      }
    `,
    missionHeading: css`
      text-transform: uppercase;
    `,
    subheading: css`
      font-family: var(--body);
      font-size: var(--fs-16);
      font-weight: 450;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: ${colors.gray60};
      margin: 1.25em 0 3em;
      line-height: 1;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      {eyebrow && <div css={styles.eyebrow}>{eyebrow}</div>}
      <Heading css={[styles.heading]}>{heading}</Heading>
      {subheading && (
        <Subheading css={styles.subheading}>{subheading}</Subheading>
      )}
    </div>
  )
}

export default ArticleHeading
