import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import { useEffect, useState } from 'react'

import { fieldStyles } from '../../styles/fieldStyles'
import FormCheckbox from './FormCheckbox'

interface Props {
  data: Queries.FormCheckboxArrayFragment | null
  onChange: (name: string, value: string) => void
  isHidden?: boolean
}

const FormCheckboxArray = ({
  data,
  isHidden,
  onChange,
}: Props): JSX.Element => {
  const { label, options } = data || {}

  const name = kebabCase(label || '')

  const [value, setValue] = useState<string[]>([])

  const handleChange = (checkboxName: string, checked: string) => {
    const checkboxLabel = options?.find(
      option => kebabCase(option?.label || '') === checkboxName
    )?.label

    const getValue = (prev: string[]) => {
      if (checkboxLabel) {
        const array = Array.from(prev)
        const optIndex = array.indexOf(checkboxLabel)
        optIndex > -1 && array.splice(optIndex, 1)
        checked && array.push(checkboxLabel)
        return array
      } else {
        return []
      }
    }
    checkboxLabel && setValue(prev => getValue(prev))
  }

  useEffect(() => {
    if (isHidden) {
      onChange(name, '')
    } else {
      onChange(
        name,
        value.join(`
`)
      )
    }
  }, [onChange, name, value, isHidden])

  const styles = {
    container: css`
      position: relative;
      min-width: 100%;
      padding: 0;
      margin: 0;
      border: none;
    `,
    inputBase: css`
      padding: 0.5em 0.5em 1em;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 0 1em;
      background: transparent;
    `,
    label: css`
      position: relative;
      transform: none;
      top: 0;
      padding: 1.125em 0 0;
      white-space: normal;
    `,
  }

  if (isHidden) {
    return (
      <input
        type="hidden"
        name={name}
        aria-hidden
      />
    )
  }

  return (
    <fieldset
      name={name}
      id={name}
      css={[
        fieldStyles.container(),
        fieldStyles.inputBase,
        styles.container,
      ]}
    >
      {/* Trying to get Netlify to recognize the proper field name */}
      <input
        type="hidden"
        name={name}
        aria-hidden
      />
      <div>
        <legend
          css={[
            fieldStyles.label,
            styles.label,
            data?.required && fieldStyles.required,
          ]}
        >
          {label}
        </legend>
      </div>
      <div css={[styles.inputBase]}>
        {options?.map((option, i) => (
          <FormCheckbox
            key={i}
            data={option}
            fieldsetName={name}
            onChange={handleChange}
          />
        ))}
      </div>
    </fieldset>
  )
}

export const FormCheckboxArrayFragment = graphql`
  fragment FormCheckboxArray on DatoCmsFormCheckboxArray {
    __typename
    id: originalId
    label
    required
    options {
      ...FormCheckboxOption
    }
  }
`

export default FormCheckboxArray
