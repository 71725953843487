import { css, keyframes } from '@emotion/react'
import { parseToHsl } from 'polished'

import { breakpoints } from './variables'

export const mq = (minMax: 'min' | 'max' = 'max') => {
  type breakpoints = typeof breakpoints
  const mqObject: { [Property in keyof breakpoints]: string } =
    Object.create(breakpoints)
  const mqArray = Object.keys(breakpoints) as Array<keyof breakpoints>

  mqArray.forEach(key => {
    mqObject[key] = `@media (${minMax}-width: ${
      breakpoints[key] + (minMax === 'min' ? 1 : 0)
    }px)`
  })

  return mqObject
}

export const absoluteFill = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const baseGrid = css`
  position: relative;
  display: grid;
  width: 100%;
  --gtr: var(--gtr-36);
  --grid-margin: calc(var(--margin) - var(--gtr));
  grid-template-columns:
    var(--grid-margin)
    repeat(12, 1fr)
    var(--grid-margin);
  column-gap: var(--gtr);
`

export const widthInCols = ({
  count = 0,
  gridWidth = 'var(--grid-w)',
  gutter = 'var(--gtr, var(--gtr-36))',
  margin = 'var(--margin)',
}) =>
  `calc(${count} * ((${gridWidth} - 2 * ${margin} - 11 * ${gutter}) / 12) + ${
    count - 1
  } * ${gutter})`

export const bezier = {
  bounce: `cubic-bezier(0.33, 3, 0.25, 0.5)`,
  easeOut: `cubic-bezier(0.25, 0.5, 0.33, 1)`,
  easeIn: `cubic-bezier(0.67, 0, 0.75, 0.5)`,
}

export const animateIn = keyframes`
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`

export const scrim = (
  startColor = 'black',
  startOpacity = 1,
  direction = 'to top'
) => {
  const scrimCoordinates = {
    '0': 1,
    '10.34': 0.9854227405,
    '19.97': 0.944606414,
    '28.94': 0.8819241983,
    '37.32': 0.8017492711,
    '45.14': 0.7084548104999999,
    '52.48': 0.6064139942,
    '59.38': 0.5,
    '65.89': 0.3935860058,
    '72.08': 0.29154518949999997,
    '77.99': 0.19825072889999995,
    '83.68': 0.11807580169999998,
    '89.21': 0.055393585999999995,
    '94.63': 0.014577259499999995,
    '100': 0,
  }
  const hsl = parseToHsl(startColor)
  const stops = Object.keys(scrimCoordinates).map(colorStop => {
    return `hsla(${hsl.hue}, ${hsl.saturation * 100}%, ${
      hsl.lightness * 100
    }%, ${
      scrimCoordinates[colorStop as keyof typeof scrimCoordinates] *
      startOpacity
    }) ${colorStop}%`
  })
  return `linear-gradient(${direction}, ${stops
    .sort()
    .reverse()
    .toString()});`
}

export const aspectRatio = (ar: number) => css`
  aspect-ratio: ${ar};
  @supports not (aspect-ratio: ${ar}) {
    &::before {
      content: '';
      float: left;
      padding-top: calc((${ar}) * 100%);
    }
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
`
