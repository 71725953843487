import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { bezier } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import DocumentLink from './DocumentLink'
import ExternalLink from './ExternalLink'
import FormLink from './FormLink'
import PageLink from './PageLink'
import VideoLink from './VideoLink'

export type DatoLinkData =
  | Queries.PageLinkFragment
  | Queries.ExternalLinkFragment
  | Queries.FormLinkFragment
  | Queries.DocumentLinkFragment
  | Queries.VideoLinkFragment

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: DatoLinkData | null
  showIcon?: boolean
  styleVariant?: 'LOWERCASE' | 'BUTTON'
  disableLightboxLinks?: boolean
}

const DatoLink = ({
  data,
  showIcon,
  styleVariant,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element | undefined => {
  const styles = {
    link: css`
      position: relative;
      color: var(--link-color, currentColor);
      --font-weight: 500;
      font-weight: var(--font-weight);
      display: block;
      max-width: fit-content;
      padding: 0.5em 0;
      margin: 0.5em 0;
      text-transform: uppercase;
      letter-spacing: 0.025em;
      text-decoration: none;
      line-height: 1;

      transition: all 200ms ease;

      > span {
        position: relative;
      }
      ${styleVariant === 'LOWERCASE' &&
      css`
        text-transform: none;
        letter-spacing: 0;
        margin: 0.25em 0;
      `}
      ${styleVariant === 'BUTTON' &&
      css`
        padding: 0.75em ${showIcon ? '1.5em' : '0.75em'} 0.75em 0.75em;
        margin: 1.5em 0 1.5em;
        border: 2px solid var(--link-color, currentColor);
        border-radius: 0.5em;
        box-sizing: border-box;
        transition: all 300ms ease;
        &:before {
          content: '';
          position: absolute;
          height: 1em;
          width: 0%;
          border-radius: 50%;
          opacity: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: var(--button-hover-bg-color, ${colors.apricot});
          transition: all 200ms ${bezier.easeIn};
          box-sizing: border-box;
        }
        @media (hover: hover) {
          &&:hover {
            color: var(--button-hover-color, #fff);
            border-color: var(
              --button-hover-bg-color,
              ${colors.apricot}
            );
            &:before {
              width: calc(100% + 4px);
              height: calc(100% + 4px);
              border-radius: 0.5em;
              opacity: 1;
              transition: all 300ms ${bezier.easeOut};
            }
            ${showIcon &&
            css`
              && svg {
                transform: translate3d(0, 0, 0);
              }
            `}
          }
        }
      `}
    `,
  }

  if (data) {
    switch (data.__typename) {
      case 'DatoCmsPageLink':
        return (
          <PageLink
            data={data}
            showIcon={showIcon}
            css={styles.link}
            {...props}
          />
        )
      case 'DatoCmsExternalLink':
        return (
          <ExternalLink
            data={data}
            showIcon={showIcon}
            css={styles.link}
            {...props}
          />
        )
      case 'DatoCmsFormLink':
        return (
          <FormLink
            data={data}
            showIcon={showIcon}
            css={styles.link}
            {...props}
          />
        )
      case 'DatoCmsDocumentLink': {
        return (
          <DocumentLink
            data={data}
            showIcon={showIcon}
            css={styles.link}
            {...props}
          />
        )
      }
      case 'DatoCmsVideoLink': {
        return (
          <VideoLink
            data={data}
            showIcon={showIcon}
            css={styles.link}
            {...props}
          />
        )
      }
    }
  }
}

export default DatoLink
