import { Link, graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import DatoLinkIcon from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: Queries.PageLinkFragment | null
  showIcon?: boolean
}

const PageLink = ({ data, showIcon, ...props }: Props): JSX.Element => {
  return (
    <Link
      to={`/${data?.page?.slug}${data?.page?.slug ? '/' : ''}`}
      {...props}
    >
      <span>{data?.linkText}</span>
      {showIcon && <DatoLinkIcon iconType="ARROW" />}
    </Link>
  )
}

export const PageLinkFragment = graphql`
  fragment PageLink on DatoCmsPageLink {
    id: originalId
    __typename
    linkText
    page {
      ... on DatoCmsHomePage {
        id: originalId
        slug
      }
      ... on DatoCmsInteriorPage {
        id: originalId
        slug
      }
      ... on DatoCmsNetworkSite {
        id: originalId
        slug
      }
      ... on DatoCmsNewsPage {
        id: originalId
        slug
      }
    }
  }
`

export default PageLink
