import { Global, css } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'

import '../fonts/_fontface.css'
import { mq } from './mixins'
import { colors } from './variables'

const GlobalStyles = () => {
  const globalStyles = css`
    ${emotionNormalize}

    :root {
      --sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji';
      --slab-serif: Menlo, Consolas, Monaco, Liberation Mono,
        Lucida Console, monospace;
      --serif: Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif',
        Lucida, 'DejaVu Serif', 'Bitstream Vera Serif',
        'Liberation Serif', Georgia, serif;

      --ff-body: 'Brandon Text', var(--sans-serif);
      --ff-display: 'Larken', var(--serif);

      /* Font Sizes */
      --fs-144: calc(4rem + 6.667vw);
      --fs-108: calc(4rem + 4.167vw);
      --fs-84: calc(3.875rem + 2.6vw);
      --fs-72: calc(3.3125rem + 2.2375vw);
      --fs-60: max(3rem, 2.75rem + 1.875vw);
      --fs-54: max(2.75rem, 2.5rem + 1.5625vw);
      --fs-48: max(2.5rem, 2.25rem + 1.25vw);
      --fs-36: max(2.25rem, 2rem + 0.833vw);
      --fs-30: max(2rem, 1.5rem + 0.833vw);
      --fs-24: max(1.667rem, 1.5rem + 0.417vw);
      --fs-21: max(1.5rem, 1.25rem + 0.417vw);
      --fs-18: max(1.333rem, 1rem + 0.417vw);
      --fs-16: max(1.25rem, 0.8333rem + 0.417vw);
      --fs-15: max(1.167rem, 0.75rem + 0.417vw);
      --fs-14: 1.167rem;
      --fs-13: 1.0888rem;

      /* Padding/Gutters/Margins */
      --gtr-12: max(1.25vw, 0.5rem);
      --gtr-24: max(1.67vw, 0.75rem);
      --gtr-36: max(2.5vw, 1rem);
      --gtr-54: max(3.75vw, 2rem);
      --gtr-72: max(5vw, 3rem);
      --gtr-108: max(7.5vw, 4.5rem);
      --gtr-144: max(10vw, 6rem);

      --margin: var(--gtr-144);
      ${mq().m} {
        --margin: var(--gtr-108);
      }
      ${mq().s} {
        --margin: var(--gtr-72);
      }

      --row-18: calc(0.5rem + 0.2vw + var(--gtr-12));
      --row-36: calc(1rem + 0.416vw + var(--gtr-12));
      --row-54: calc(1.5rem + 0.625vw + var(--gtr-24));
      --row-72: calc(2rem + 0.833vw + var(--gtr-36));
      --row-108: calc(3rem + 1.25vw + var(--gtr-54));
      --row-144: calc(4rem + 1.67vw + var(--gtr-72));

      --grid-w: 100vw;
      --col-w: calc(
        (var(--grid-w) - 2 * var(--margin) - 11 * var(--gtr-36)) / 12
      );
    }

    html {
      font-size: 12px;
      ${mq('min').l} {
        font-size: calc(9px + 0.21vw);
      }
      scroll-behavior: smooth;
      background: ${colors.teal};
    }

    body {
      font-family: var(--ff-body);
      font-size: var(--fs-18);
      line-height: 1.5;
    }
    h1,
    h2,
    h3 {
      font-family: var(--ff-display);
      font-weight: 400;
      line-height: 1;
    }
    h4,
    h5,
    h6 {
      font-family: var(--ff-body);
      font-weight: 400;
      line-height: 1.125;
    }
    p {
      line-height: inherit;
    }
    a {
      transition: color 300ms ease;
    }
    button {
      appearance: none;
      border: none;
      background-color: transparent;
      color: inherit;
      padding: 0;
      cursor: pointer;
      transition:
        color 300ms ease,
        background 300ms ease,
        border 300ms ease;
    }
    input,
    textarea,
    select {
      border-radius: 0;
      &:focus {
        outline: none;
      }
    }
    *:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        transition: all 0s 99999s;
        border-radius: 0;
      }
    }

    [data-gatsby-image-wrapper] {
      width: 100%;
      > div {
        max-width: 100% !important;
      }
    }
  `
  return <Global styles={globalStyles} />
}

export default GlobalStyles
