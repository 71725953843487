import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { MediaCarousel } from '@/features/carousel'
import { BodyTextBlock } from '@/features/common-blocks'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?:
    | readonly (
        | Queries.BodyTextBlockFragment
        | Queries.MediaCarouselFragment
        | null
      )[]
    | null
  disableLightboxLinks?: boolean
}

const ArticleBody = ({
  data,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      display: contents;
    `,
    block: css`
      margin-left: var(--margin);
      margin-right: var(--margin);
      color: ${colors.gray30};
      max-width: 85ch;
      h2 {
        color: ${colors.tealDark};
        margin: 2em 0 0.5em;
        font-size: var(--fs-30);
      }
      h3 {
        font-size: var(--fs-24);
        font-family: var(--ff-body);
        margin: 2em 0 0.5em;
        color: ${colors.tealDark};
        font-weight: 500;
      }
      h2,
      h3 {
        strong,
        b {
          font-weight: inherit;
        }
      }
      a {
        color: ${colors.teal};
        @media (hover: hover) {
          &:hover {
            color: ${colors.apricot};
          }
        }
      }
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    `,
    carousel: css`
      max-width: var(--grid-w);
      margin: 2.5em 0;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      {data?.map(block => {
        switch (block?.__typename) {
          case 'DatoCmsBodyTextBlock':
            return (
              <BodyTextBlock
                data={block}
                css={styles.block}
                disableLightboxLinks={disableLightboxLinks}
                key={block.id}
              />
            )
          case 'DatoCmsMediaCarousel':
            return (
              <MediaCarousel
                data={block}
                layout="ARTICLE"
                css={[styles.block, styles.carousel]}
                key={block.id}
              />
            )
        }
      })}
    </div>
  )
}

export default ArticleBody
