import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoGatsbyImage } from '@/features/common-blocks'
import { LightboxLink } from '@/features/lightbox'
import { absoluteFill, bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data?: Queries.TeamMemberThumbnailFragment | null
}

const TeamMemberThumbnail = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      display: grid;
      justify-items: center;
      align-content: flex-start;
      text-decoration: none;
      pointer-events: none;
    `,
    imageContainer: css`
      position: relative;
      display: flex;
      width: calc(100% - var(--gtr-108));
      border-radius: 50%;
      overflow: hidden;
      pointer-events: all;
      &:before {
        content: '';
        ${absoluteFill};
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        z-index: 1;
      }
      ${mq().s} {
        width: calc(100% - var(--gtr-72));
      }
    `,
    image: css`
      transform: scale3d(1, 1, 1);
      transition: transform 500ms ${bezier.easeOut};
      img {
        border-radius: 50%;
      }
      @media (hover: hover) {
        a:hover & {
          transform: scale3d(1.05, 1.05, 1);
        }
      }
    `,
    name: css`
      font-size: var(--fs-30);
      color: ${colors.teal};
      margin: 0;
      padding: 0.5em 0 0;
      pointer-events: all;
      transition: color 300ms ease;
      @media (hover: hover) {
        a:hover & {
          color: ${colors.apricot};
        }
      }
      ${mq().s} {
        font-size: var(--fs-24);
      }
    `,
    jobTitle: css`
      margin: 0;
      padding: 0.5em 0 1em;
      color: ${colors.gray40};
      text-decoration: uppercase;
      font-size: var(--fs-18);
      pointer-events: all;
      transition: color 300ms ease;
      @media (hover: hover) {
        a:hover & {
          color: ${colors.gray60};
        }
      }
    `,
  }
  return (
    <LightboxLink
      css={styles.container}
      data={data}
      {...props}
    >
      <div css={styles.imageContainer}>
        <DatoGatsbyImage
          css={styles.image}
          image={data?.photo?.gatsbyImageData}
          alt={data?.photo?.alt || `${data?.name} | ${data?.jobTitle}`}
        />
      </div>
      <h3 css={styles.name}>{data?.name}</h3>
      <h4 css={styles.jobTitle}>{data?.jobTitle}</h4>
    </LightboxLink>
  )
}

export const TeamMemberThumbnailFragment = graphql`
  fragment TeamMemberThumbnail on DatoCmsTeamMember {
    ...TeamMemberArticle
  }
`

export default TeamMemberThumbnail
