import { ReactNode } from 'react'

import { LightboxContextProvider } from '@/features/lightbox'

import { NavContextProvider } from '../../contexts/navContext'

const ContextWrapper = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return (
    <LightboxContextProvider>
      <NavContextProvider>{children}</NavContextProvider>
    </LightboxContextProvider>
  )
}

export default ContextWrapper
