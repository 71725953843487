import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { LightboxLink } from '@/features/lightbox'

import DatoLinkIcon from './DatoLinkIcon'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: Queries.VideoLinkFragment | null
  showIcon?: boolean
}

const VideoLink = ({
  data,
  showIcon,
  ...props
}: Props): JSX.Element => {
  const styles = {
    link: css``,
  }
  return (
    <LightboxLink
      data={data?.video}
      layout="CENTERED"
      css={styles.link}
      {...props}
    >
      {showIcon && <DatoLinkIcon iconType="VIDEO" />}
      <span>{data?.linkText}</span>
    </LightboxLink>
  )
}

export const VideoLinkFragment = graphql`
  fragment VideoLink on DatoCmsVideoLink {
    __typename
    id: originalId
    linkText
    video {
      ...LightboxVideo
    }
  }
`

export default VideoLink
