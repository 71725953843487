// Modified GatsbyImage component to handle Gatsby's incompatible type generation
import {
  GatsbyImage,
  GatsbyImageProps,
  IGatsbyImageData,
} from 'gatsby-plugin-image'

interface Props extends Omit<GatsbyImageProps, 'image'> {
  image: IGatsbyImageData | Record<string, unknown> | null | undefined
}

const DatoGatsbyImage = ({ image, ...props }: Props): JSX.Element => {
  return (
    <GatsbyImage
      image={image as unknown as IGatsbyImageData}
      {...props}
    />
  )
}

export default DatoGatsbyImage
