import { graphql, useStaticQuery } from 'gatsby'
import { Fragment, useLayoutEffect } from 'react'

interface Props {
  seoMetaTags:
    | {
        tags: Record<string, unknown> | null
      }
    | null
    | undefined
  hideSuffix?: boolean
  lang?: string
}

export interface SeoMetaTagsData {
  tags: (
    | {
        tagName: 'title'
        content: string
      }
    | {
        tagName: 'meta'
        attributes: {
          name?: string
          property?: string
          content: string
        }
      }
  )[]
}

const Seo = ({
  seoMetaTags,
  hideSuffix,
  lang = 'en',
}: Props): JSX.Element => {
  interface ISeoQuery {
    site: { globalSeo: { titleSuffix: string } }
  }
  const { site } = useStaticQuery<ISeoQuery>(graphql`
    query Seo {
      site: datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
    }
  `)

  const metaTags = seoMetaTags as SeoMetaTagsData | null

  const { titleSuffix } = site.globalSeo

  useLayoutEffect(() => {
    document.documentElement.lang = lang
  }, [lang])

  return (
    <Fragment>
      {metaTags?.tags.map((tag, i) => {
        if (tag.tagName === 'title') {
          return (
            <title key={i}>
              {!hideSuffix
                ? tag.content
                : tag.content.replace(titleSuffix || '', '')}
            </title>
          )
        }
        if (tag.tagName === 'meta') {
          if (hideSuffix && tag.attributes.property === 'og:type') {
            return (
              <meta
                property="og:type"
                content="website"
                key={i}
              />
            )
          } else {
            return (
              <meta
                name={tag.attributes.name || undefined}
                property={tag.attributes.property || undefined}
                content={tag.attributes.content}
                key={i}
              />
            )
          }
        }
      })}
    </Fragment>
  )
}

export default Seo
