import { css } from '@emotion/react'
import { CSSInterpolation } from '@emotion/serialize'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ComponentPropsWithoutRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { useElementRect } from '@/hooks/useElementRect'

import DatoGatsbyImage from '../DatoGatsbyImage'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.ImageFocalDataFragment | null | undefined
  image: IGatsbyImageData | Record<string, unknown> | null | undefined
  aspectRatio?: number
  gatsbyImageCss?: CSSInterpolation
  loading?: 'lazy' | 'eager'
}

const GatsbyImageFocused = ({
  image,
  data,
  aspectRatio,
  gatsbyImageCss,
  loading,
  ...props
}: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { width, height } = useElementRect(ref.current)

  const [objectPosition, setObjectPosition] = useState<
    { x: string; y: string } | undefined
  >()

  useLayoutEffect(() => {
    const originalAspectRatio = data?.sizes?.aspectRatio || 0
    const containerAR = (width && height && width / height) || 0
    let trueFP = undefined as { x: number; y: number } | undefined
    if (aspectRatio) {
      const fpRatioX = aspectRatio / originalAspectRatio
      const fpRatioY = originalAspectRatio / aspectRatio
      const getFP = (ratio: number, fp: number) => {
        if (ratio < 1) {
          if (fp < ratio / 2 || 1 - fp < ratio / 2) {
            return fp / ratio
          } else {
            return 0.5
          }
        } else {
          return fp
        }
      }
      if (fpRatioX && data?.focalPoint) {
        trueFP = {
          x: getFP(fpRatioX, data.focalPoint.x),
          y: getFP(fpRatioY, data.focalPoint.y),
        }
      }
    } else {
      trueFP = data?.focalPoint || undefined
    }
    const ar = aspectRatio || originalAspectRatio
    const ratioX = ar / containerAR
    const ratioY = containerAR / ar
    const getPosition = (ratio: number, fp: number) => {
      const position = (fp - 0.5) * (ratio / (ratio - 1)) + 0.5
      return ratio > 1 ? Math.max(Math.min(position, 1), 0) : 0.5
    }
    if (ratioX && ratioY && trueFP) {
      setObjectPosition({
        x: getPosition(ratioX, trueFP?.x) * 100 + '%',
        y: getPosition(ratioY, trueFP?.y) * 100 + '%',
      })
    }
  }, [aspectRatio, data, width, height])

  const styles = {
    gatsbyImageWrapper: css`
      width: 100%;
      height: 100%;
      ${!objectPosition &&
      css`
        [data-main-image] {
          opacity: 0 !important;
        }
        [data-placeholder-image] {
          opacity: 1 !important;
        }
      `}
    `,
  }
  return (
    <div
      ref={ref}
      style={
        objectPosition && {
          objectPosition: `${objectPosition?.x} ${objectPosition?.y}`,
        }
      }
      {...props}
    >
      <DatoGatsbyImage
        css={[styles.gatsbyImageWrapper, gatsbyImageCss]}
        image={image}
        alt={data?.alt || ''}
        style={{ objectPosition: 'inherit' }}
        objectPosition="inherit"
        loading={loading}
      />
    </div>
  )
}

export const ImageFocalDataFragment = graphql`
  fragment ImageFocalData on DatoCmsFileField {
    isImage
    alt
    sizes {
      aspectRatio
    }
    focalPoint {
      x
      y
    }
  }
`

export default GatsbyImageFocused
