import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

interface Props {
  mobileNavIsOpen: boolean
  setMobileNavIsOpen: Dispatch<SetStateAction<boolean>>
}

const NavContext = createContext<Props | undefined>(undefined)

export const useNavContext = () => useContext(NavContext) as Props

export const NavContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)

  return (
    <NavContext.Provider
      value={{
        mobileNavIsOpen,
        setMobileNavIsOpen: value => setMobileNavIsOpen(value),
      }}
    >
      {children}
    </NavContext.Provider>
  )
}
