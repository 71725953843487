import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { DatoGatsbyImage } from '@/features/common-blocks'
import { aspectRatio } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.BadgeFragment | null
}

const Badge = ({ data, ...props }: Props): JSX.Element => {
  const styles = {
    image: css`
      width: auto;
      height: 100%;
      ${aspectRatio((data?.width || 0) / (data?.height || 0))}
    `,
  }
  switch (data?.format) {
    case 'svg':
      return (
        <div {...props}>
          <img
            src={data.url || ''}
            alt={data.alt || ''}
            title={data.title || ''}
            css={styles.image}
          />
        </div>
      )
    default:
      return (
        <div {...props}>
          <DatoGatsbyImage
            image={data?.gatsbyImageData}
            alt={data?.alt || ''}
            title={data?.title || ''}
            css={styles.image}
          />
        </div>
      )
  }
}

export const BadgeFragment = graphql`
  fragment Badge on DatoCmsFileField {
    __typename
    id: originalId
    format
    url
    gatsbyImageData(height: 240)
    alt
    title
    width
    height
  }
`

export default Badge
