import { graphql } from 'gatsby'
import { HTMLAttributes } from 'react'

import useLocalFileUrl from '@/hooks/useLocalFileUrl'

import DatoLinkIcon from './DatoLinkIcon'

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  data?: Queries.DocumentLinkFragment | null
  showIcon?: boolean
}

const DocumentLink = ({
  data,
  showIcon,
  ...props
}: Props): JSX.Element => {
  const localFileUrl = useLocalFileUrl(data?.document?.localFileId)
  return (
    <a
      href={localFileUrl || data?.document?.url || undefined}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <span>
        {data?.linkText}
        {showIcon && <DatoLinkIcon iconType="DOWNLOAD" />}
      </span>
    </a>
  )
}

export default DocumentLink

export const DocumentLinkFragment = () => graphql`
  fragment DocumentLink on DatoCmsDocumentLink {
    id: originalId
    __typename
    linkText
    document {
      localFileId
      url
    }
  }
`
