import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, useState } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

import { VideoPlayIcon } from '@/features/common-blocks'
import { aspectRatio } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.LightboxVideoFragment | null
  playerProps?: ReactPlayerProps
  layout: 'LIGHTBOX' | 'PAGE'
}

const LightboxVideo = ({
  data,
  playerProps,
  ...props
}: Props): JSX.Element => {
  const [playing, setPlaying] = useState(false)
  const styles = {
    container: css`
      ${aspectRatio(
        (data?.video?.width || 0) / (data?.video?.height || 0)
      )};
    `,
    player: css``,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <ReactPlayer
        url={data?.video?.url || undefined}
        light={data?.video?.thumbnailUrl || true}
        playIcon={<VideoPlayIcon />}
        width="100%"
        height="100%"
        css={styles.player}
        onClickPreview={() => setPlaying(true)}
        playing={playing}
        {...playerProps}
      />
    </div>
  )
}

export const LightboxVideoFragment = graphql`
  fragment LightboxVideo on DatoCmsLightboxVideo {
    __typename
    id: originalId
    video {
      url
      thumbnailUrl
      title
      width
      height
    }
    slug
    seoMetaTags {
      tags
    }
  }
`
export default LightboxVideo
