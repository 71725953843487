import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { breakpoints } from '@/theme/variables'

import DatoGatsbyImage from '../DatoGatsbyImage'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.ResponsiveGraphicItemFragment | null
}

const ResponsiveGraphicItem = ({
  data,
  ...props
}: Props): JSX.Element => {
  const getImage = () => {
    if (data?.maxViewportWidth) {
      if (data.maxViewportWidth > breakpoints.ls) {
        return data.graphic?.largeGatsbyImageData
      }
      if (data.maxViewportWidth > breakpoints.ms) {
        return data.graphic?.mediumGatsbyImageData
      } else {
        return data?.graphic?.smallGatsbyImageData
      }
    } else {
      return data?.graphic?.largeGatsbyImageData
    }
  }
  const styles = {
    image: css`
      width: 100%;
      height: auto;
    `,
  }
  switch (data?.graphic?.format) {
    case 'svg':
      return (
        <div {...props}>
          <img
            src={data.graphic.url || ''}
            alt={data.graphic.alt || ''}
            css={styles.image}
          />
        </div>
      )
    default:
      return (
        <div {...props}>
          <DatoGatsbyImage
            image={getImage()}
            alt={data?.graphic?.alt || ''}
            css={styles.image}
          />
        </div>
      )
  }
}

export const ResponsiveGraphicItemFragment = graphql`
  fragment ResponsiveGraphicItem on DatoCmsResponsiveGraphicItem {
    __typename
    id: originalId
    graphic {
      format
      url
      largeGatsbyImageData: gatsbyImageData(width: 1620)
      mediumGatsbyImageData: gatsbyImageData(width: 960)
      smallGatsbyImageData: gatsbyImageData(width: 540)
      alt
    }
    maxViewportWidth
  }
`

export default ResponsiveGraphicItem
