import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import {
  ChangeEvent,
  Fragment,
  useEffect,
  useId,
  useState,
} from 'react'

import { useElementHeight } from '@/hooks/useElementRect'
import { absoluteFill } from '@/theme/mixins'

import { fieldStyles } from '../../styles/fieldStyles'

interface FieldProps {
  data?: Queries.FormTextAreaFragment | null
  onChange: (name: string, value: string) => void
  isHidden?: boolean
}

const FormTextArea = ({
  data,
  onChange,
  isHidden,
}: FieldProps): JSX.Element => {
  const name = data?.label ? kebabCase(data?.label) : undefined

  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')
  const handleFocus = () => {
    if (!shrink) {
      setShrink(true)
    }
  }
  const handleBlur = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }
  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (isHidden) {
      name && onChange(name, '')
    } else {
      name && onChange(name, value)
    }
  }, [onChange, name, value, isHidden])

  const uniqueId = useId()

  const [labelRef, setLabelRef] = useState<HTMLElement | null>(null)
  const labelHeight = useElementHeight(labelRef)

  const styles = {
    sizer: css`
      display: block;
      visibility: hidden;
      /* min-height: 9em; */
      padding-top: 1.75em;
      padding-bottom: 1.5em;
      min-height: 9em;
      min-height: max(9em, 3.25em + ${labelHeight}px);
      transition: min-height 150ms ease;
    `,
    textArea: css`
      ${absoluteFill}
      resize: none;
      height: 100%;
      border: none;
      padding-top: 1.75em;
      padding-bottom: 1.5em;
    `,
  }

  if (isHidden) {
    return (
      <input
        type="hidden"
        name={name}
        aria-hidden
      />
    )
  }

  return (
    <div css={fieldStyles.container('Full')}>
      <label
        htmlFor={name + uniqueId}
        css={[
          fieldStyles.label,
          shrink && fieldStyles.shrink,
          data?.required && fieldStyles.required,
        ]}
        ref={node => setLabelRef(node)}
      >
        {data?.label}
      </label>
      <div css={fieldStyles.inputBase}>
        <span css={[fieldStyles.input, styles.sizer]}>
          {value.split(/\n/g).map((text, i) => (
            <Fragment key={i}>
              {text}
              <br />
            </Fragment>
          ))}
        </span>
        <textarea
          css={[fieldStyles.input, styles.textArea]}
          name={name}
          id={name + uniqueId}
          required={data?.required || undefined}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </div>
  )
}

export const FormTextAreaFragment = graphql`
  fragment FormTextArea on DatoCmsFormTextArea {
    __typename
    id: originalId
    label
    required
  }
`

export default FormTextArea
