export const breakpoints = {
  l: 1440,
  ls: 1260,
  ml: 1080,
  m: 900,
  ms: 720,
  s: 540,
}

export const colors = {
  apricot: '#F18D6B',
  apricotLight: '#ffb197',
  cream: '#FBF0E5',
  mauve: '#8E7196',
  mauveLight: '#b092b8',
  teal: '#468b9b',
  tealMid: '#3C7E8F',
  tealMidLight: '#80BBC9',
  tealLight: '#B3E6F2',
  tealExtraLight: '#c9e3e8',
  tealDark: '#13485B',
  tealDarker: '#0B3E51',
  tealExtraDark: '#09212a',
  gray97: 'hsl(191, 5%, 97%)',
  gray95: 'hsl(191, 5%, 95%)',
  gray85: 'hsl(191, 5%, 85%)',
  gray75: 'hsl(191, 5%, 75%)',
  gray60: 'hsl(191, 5%, 60%)',
  gray40: 'hsl(191, 6%, 40%)',
  gray30: 'hsl(191, 8%, 30%)',
  gray20: 'hsl(191, 10%, 20%)',
}
