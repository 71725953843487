import { css } from '@emotion/react'
import { format } from 'date-fns'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import {
  ArticleBody,
  ArticleContainer,
  ArticleHeading,
  ArticleLayoutOptions,
} from '@/features/article-sections'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data?: Queries.NewsArticleFragment | null
  layout: ArticleLayoutOptions
  disableLightboxLinks?: boolean
}

const NewsArticle = ({
  data,
  layout,
  disableLightboxLinks,
  ...props
}: Props): JSX.Element => {
  const getPublicationDate = () => {
    const date = data?.meta.createdAt && new Date(data.meta.createdAt)
    return date && format(date, 'MMM d, y')
  }

  const styles = {
    category: css`
      display: inline-block;
      text-transform: uppercase;
      font-weight: 450;
      color: ${colors.teal};
      margin: 0.25em 0 0;
    `,
    divider: css`
      color: rgba(0, 0, 0, 0.25);
    `,
    date: css`
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    `,
  }
  return (
    <ArticleContainer
      layout={layout}
      {...props}
    >
      <ArticleHeading
        heading={data?.heading}
        eyebrow={
          <div>
            <div css={styles.category}>
              {data?.category?.categoryName}
            </div>
            <span css={styles.divider}>&emsp;|&emsp;</span>
            <div css={styles.date}>{getPublicationDate()}</div>
          </div>
        }
      />
      <ArticleBody
        data={data?.blocks}
        disableLightboxLinks={disableLightboxLinks}
      />
    </ArticleContainer>
  )
}

export const NewsArticleFragment = graphql`
  fragment NewsArticle on DatoCmsNewsArticle {
    id: originalId
    __typename
    heading
    blocks {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsMediaCarousel {
        ...MediaCarousel
      }
    }
    meta {
      createdAt
    }
    category {
      categoryName
    }
    slug
    seoMetaTags {
      tags
    }
  }
`

export default NewsArticle
