import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useId,
  useState,
} from 'react'

import { absoluteFill } from '@/theme/mixins'

import { fieldStyles } from '../../styles/fieldStyles'

interface Props {
  data:
    | Queries.FormCheckboxFragment
    | Queries.FormCheckboxOptionFragment
    | null
  fieldsetName?: string
  onChange: (name: string, value: string) => void
  isHidden?: boolean
}

const FormCheckbox = ({
  data,
  fieldsetName,
  onChange,
  isHidden,
}: Props): JSX.Element => {
  const { label, id } = data || {}

  const required =
    data?.__typename === 'DatoCmsFormCheckbox' && data.required

  const name = kebabCase(label || '')

  const [checked, setChecked] = useState(false)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked)
    },
    []
  )

  useEffect(() => {
    if (isHidden) {
      onChange(name, '')
    } else {
      onChange(name, checked.toString())
    }
  }, [onChange, name, checked, isHidden])

  const uniqueId = useId()

  const styles = {
    inputOption: css`
      position: relative;
      padding: 0.5em;
      box-sizing: border-box;
      max-width: fit-content;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
      label,
      input {
        cursor: inherit;
      }
      input {
        opacity: 0;
        ${absoluteFill}
      }
    `,
  }

  if (isHidden) {
    return (
      <input
        type="hidden"
        name={name}
        aria-hidden
      />
    )
  }

  return (
    <div css={[fieldStyles.input, styles.inputOption]}>
      <div
        css={fieldStyles.checkbox}
        data-checked={checked}
      />
      <label
        htmlFor={id + uniqueId}
        css={required && fieldStyles.required}
      >
        {label}
      </label>
      <input
        name={fieldsetName ? kebabCase(fieldsetName) : name}
        required={required || undefined}
        type="checkbox"
        onChange={handleChange}
        value={id}
        id={id + uniqueId}
      />
    </div>
  )
}

export const FormCheckboxFragment = graphql`
  fragment FormCheckbox on DatoCmsFormCheckbox {
    __typename
    id: originalId
    label
    required
  }
  fragment FormCheckboxOption on DatoCmsFormCheckboxOption {
    __typename
    id: originalId
    label
  }
`

export default FormCheckbox
