import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import ResponsiveGraphicItem from './ResponsiveGraphicItem'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data?: Queries.ResponsiveGraphicFragment | null
}

const ResponsiveGraphic = ({ data, ...props }: Props): JSX.Element => {
  const sortedGraphics =
    data?.responsiveGraphic &&
    [...data.responsiveGraphic].sort(
      (a, b) =>
        (b?.maxViewportWidth || Infinity) -
        (a?.maxViewportWidth || Infinity)
    )
  const styles = {
    figure: css`
      padding: 0;
      margin: 0;
    `,
    graphic: ({
      maxViewportWidth,
      minViewportWidth,
    }: {
      maxViewportWidth?: number | null
      minViewportWidth?: number | null
    }) => css`
      display: none;
      ${minViewportWidth && maxViewportWidth
        ? css`
            @media (min-width: ${minViewportWidth}px) and (max-width: ${maxViewportWidth}px) {
              display: block;
            }
          `
        : css`
            @media (min-width: ${minViewportWidth}px) {
              display: block;
            }
          `}
    `,
  }
  return (
    <figure
      css={styles.figure}
      {...props}
    >
      {sortedGraphics?.map((graphic, i, array) => (
        <ResponsiveGraphicItem
          data={graphic}
          key={graphic?.id}
          data-max={graphic?.maxViewportWidth}
          data-min={(array[i + 1]?.maxViewportWidth || 0) + 1}
          css={styles.graphic({
            maxViewportWidth: graphic?.maxViewportWidth,
            minViewportWidth: (array[i + 1]?.maxViewportWidth || 0) + 1,
          })}
        />
      ))}
    </figure>
  )
}

export const ResponsiveGraphicFragment = graphql`
  fragment ResponsiveGraphic on DatoCmsResponsiveGraphic {
    __typename
    id: originalId
    responsiveGraphic {
      ...ResponsiveGraphicItem
    }
  }
`

export default ResponsiveGraphic
